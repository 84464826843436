<template>
	<Transition :appear="true">
		<div class="overlay">
			<div class="confirmChoice">
				<span>
					<slot/>
				</span>
				<div class="button-container">
					<button class="cta" @click="() => {props.run(false)}">
						Afbryd
					</button>
					<button class="cta notSelected destructive" @click="() => {props.run(true)}">
						Accepter
					</button>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
const props = defineProps<{
	run(shouldDelete: boolean): void
}>()


</script>

<style lang="scss" scoped>
@import '@/style.scss';
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	transition-duration: 200ms;
	transition-timing-function: ease;
	transition-property: background-color;
	&.v-enter-from {
		background-color: transparent;
		.confirmChoice {
			opacity: 0;
			transform: scale(0.9);
		}
	}
	.confirmChoice {
		transition: inherit;
		transition-property: opacity, transform;
		background-color: white;
		min-height: 200px;
		max-height: 100vh;
		min-width: 300px;
		max-width: 600px;
		border-radius: 10px;
		box-shadow: 0 0 5px 2px rgba(99, 99, 99, .5);
		display: flex;
		flex-direction: column;
		padding: 20px;
		@include phone {
			max-width: 95%;
		}
		.button-container {
			margin-left: auto;
			margin-top: 30px;
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			max-width: 250px;
			gap: 10px;
		}
	}
}
</style>
