<template>
  <div :class="`account ${open ? 'open' : ''}`" :ref="'goto'">
    <div class="accountRow" @click="toggleOpen()">
		<div class="statusIcon">
			<font-awesome-icon :class="`fa-icon ${props.mvnoAccount.info.updating && props.mvnoAccount.state !== 'DELETING' ? 'spinner' : ''}`" :icon="statusIcon"/>
		</div>
        <p>{{ props.mvnoAccount.name }} {{props.mvnoAccount.number}}</p>
		<font-awesome-icon :class="`fa-icon burger ${open ? 'open' : ''}`" :icon="['fas', 'chevron-down']"/>
		<div class="" style="width: 100%; display: flex; flex-direction: column;">
			<span class="small" v-if="props.mvnoAccount.state === 'DELETING'">Under sletning</span>
			<span class="small" v-else-if="props.mvnoAccount.info.updating">Under opdatering</span>
			<span class="small" v-else-if="props.mvnoAccount.state === 'CREATING'">{{ createAtLabel }}</span>
			<span class="small" v-else-if="props.mvnoAccount.newRatePlan">Fra næste måned benyttes <b>{{ props.mvnoAccount.newRatePlanName }}</b></span>
			<span class="small" v-else-if="props.mvnoAccount.state === 'BLOCKED'">Spærret</span>
		</div>
    </div>
    <div class="content" v-if="open">
        <div class="separator"></div>
        <div class="topbar">
			<button :class="`${mode === 'usage' ? 'active' : ''}`" @click="() => mode = 'usage'">
				<font-awesome-icon class="fa-icon" :icon="['fas', 'chart-pie']"/>
				Forbrug
			</button>
			<button :class="`${mode === 'settings' ? 'active' : ''}`" @click="() => mode = 'settings'">
				<font-awesome-icon class="fa-icon" :icon="['fas', 'gear']" />
				Indstillinger
			</button>
			<button class="close" @click="toggleOpen()">
				Luk
				<font-awesome-icon class="fa-icon" :icon="['fas', 'circle-xmark']"/>
			</button>
		</div>
		<div class="inner" ref="content">
			<transition mode="out-in">
				<div v-if="mode === 'usage'" class="detailsMain">
					<div class="dateSelector">
						<p>Periode: </p>
						<select class="form-control" name="usagePeriod" id="usagePeriod" v-model="usagePeriod">
							<option :value="period" v-for="period in periods" :key="period" >{{formatPeriod(period)}}</option>
						</select>
					</div>
					<div class="regions">
						<h3>Tale forbrug</h3>
						<div class="regionTable">
							<region-row :region="'home'" :type="'HOURS'" :usageRegions="callUsage" />
							<region-row :region="'EuNordic'" :type="'HOURS'" :usageRegions="callUsage" />
							<region-row :region="'RestOfEurope'" :type="'HOURS'" :usageRegions="callUsage" />
							<region-row :region="'World1'" :type="'HOURS'" :usageRegions="callUsage" />
							<region-row :region="'World2'" :type="'HOURS'" :usageRegions="callUsage" />
							<region-row :region="'World3'" :type="'HOURS'" :usageRegions="callUsage" />
							<!-- <region-row :region="region" v-for="region in regions" :type="'HOURS'" :key="region._id" :usageRegions="callUsage"/> -->
						</div>
						<h3>Data forbrug</h3>
						<div class="regionTable">
							<region-row :region="region" v-for="region in props.regions" :type="'GB'" :key="region._id" :usageRegions="dataUsage"/>
						</div>
					</div>
				</div>
				<div v-else-if="mode === 'settings'" class="detailsMain">
					<div class="row">
						<div class="col" style="min-width: 250px;">
							<SubscriptionCard class="currentSubscription" :simple="true" :action="startChangeRatePlan" :forceMobile="true" :compact="true" :customCta="'Skift abonnement'" :noCTA="['DELETING', 'BLOCKED'].includes(mvnoAccount.state) || mvnoAccount.newRatePlan || mvnoAccount.info.updating ? true : false" :data="u.products.find(o => o._id === mvnoAccount.ratePlanObject._id) || null" :ctaClasses="''"/>
							<div v-if="mvnoAccount.newRatePlan" style="margin-top: 20px;" class="form-group">
								<label for="">Fra næste måned benyttes</label>
								<SubscriptionCard v-if="u.products.find(o => o._id === mvnoAccount.newRatePlan) && mvnoAccount.newRatePlan" class="newSubscription" :simple="true" :action="() => {wantToCancelRateplanChange = true}" :force-mobile="true" :compact="true" :custom-cta="'Annuller skifte'" :noCTA="false" :data="u.products.find(o => o._id === mvnoAccount.newRatePlan) || null" :ctaClasses="'destructive grow notSelected'"/>
							</div>
							<div class="form-group">
								<label for="subscriptionName">Navn</label>
								<div class="input-group">
									<input type="text" :disabled="props.mvnoAccount.state === 'DELETING'" name="subscriptionName" id="subscriptionName" v-model="newMvnoName" class="form-control">
									<div style="position: relative; padding-right: 5px;">
										<font-awesome-icon class="fa-icon spinner nameStatusIcon" :style="{ 'opacity': changingMvnoName ? 1 : 0 }" :icon="['fas', 'circle-notch']"/>
									</div>
								</div>
								<span class="tooltip">Navn på bruger af telefonnummeret: {{ props.mvnoAccount.number }}</span>
							</div>
							<div class="row">
								<div class="col form-group" v-if="!mvnoAccount.info.updating">
									<button v-if="canBlock" :class="`cta grow ${mvnoAccount.state !== 'BLOCKED' ? 'notSelected destructive' : ''}`" @click="wantToToggleMvnoState = true;">
										<Transition mode="out-in" style="position: relative;" >
											<font-awesome-icon v-if="togglingMvnoState" class="fa-icon loading transition" :icon="['fas', 'circle-notch']" />
											<font-awesome-icon v-else-if="mvnoAccount.state === 'BLOCKED'" class="fa-icon transition" :icon="['fas', 'check']" />
											<font-awesome-icon v-else class="fa-icon transition" :icon="['fas', 'ban']" />
										</Transition>
										{{ mvnoAccount.state === 'BLOCKED' ? 'Genåben mobil' : `Spær ${mvnoAccount.number}` }}
									</button>
									<span class="tooltip red" v-if="toggleMvnoStateError">Der skete en fejl under {{ mvnoAccount.state === 'BLOCKED' ? 'genåbning' : 'spærring' }} af {{ mvnoAccount.number }}</span>
								</div>
								<div class="col form-group">
									<button :class="`cta grow notSelected destructive`" @click="wantToCloseAccount = true;">
										Afmeld {{ mvnoAccount.number }}
									</button>
									<span class="tooltip red" v-if="deleteAccountError">Der skete en uventet fejl, nummeret er ikke slettet</span>
								</div>
							</div>
							<div class="form-group">
								<div class="panel">
									<div class="panel-header">
										<h4>Features</h4>
									</div>
									<div class="panel-body">
										<div class="list">
											<div class="list-item">
												<span>Højtakst kald (f.eks. 90 numre)</span>
												<Toggle @click="() => { features.highRate = !features.highRate; featuresChanged = true; }" :value="features.highRate" v-if="!savingFeatures" :disabled="mvnoAccount.info.updating"/>
											</div>
											<div class="list-item">
												<span>Internationale kald</span>
												<Toggle @click="() => { features.international = !features.international; featuresChanged = true; }" :value="features.international" v-if="!savingFeatures" :disabled="mvnoAccount.info.updating"/>
											</div>
											<div class="list-item">
												<span>Overtakseret SMS og varekøb</span>
												<Toggle @click="() => { features.chargedServices = !features.chargedServices; featuresChanged = true; }" :value="features.chargedServices" v-if="!savingFeatures" :disabled="mvnoAccount.info.updating"/>
											</div>
											<div class="list-item">
												<span>Optag i offentlige telefonbøger</span>
												<Toggle @click="() => { numberListed = !numberListed; featuresChanged = true; }" :value="numberListed" v-if="!savingFeatures" :disabled="mvnoAccount.info.updating"/>
											</div>
										</div>
									</div>
									<div class="panel-footer">
										<button class="cta grow small" @click="saveFeatures" :disabled="!featuresChanged || mvnoAccount.info.updating">
											<Transition mode="out-in">
												<font-awesome-icon class="fa-icon spinner" :icon="['fas', 'circle-notch']" v-if="savingFeatures"/>
												<font-awesome-icon class="fa-icon" :icon="['fas', 'floppy-disk']" v-else/>
											</Transition>
											Gem features
										</button>
										<button class="cta grow small" :disabled="!featuresChanged || mvnoAccount.info.updating" @click="resetFeatures">
											<font-awesome-icon class="fa-icon" :icon="['fas', 'rotate']"/>
											Nulstil ændringer
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col" style="min-width: 200px;" v-if="!['DELETING', 'BLOCKED'].includes(props.mvnoAccount.state)">
							<div class="form-group" v-if="qrcode && props.mvnoAccount.mvnoSim.activationCode">
								<label for="">eSIM aktivering</label>
								<img class="esimQR" :src="qrcode" alt="">
							</div>
							<div class="form-group">
								<label for="simNumber">SIM nummer (ICC)</label>
								<input type="text" class="form-control" disabled :value="mvnoAccount.mvnoSim.simNumber" style="width: 100%; max-width: unset;">
							</div>
							<div class="row">
								<div class="col">
									<div class="form-group">
										<label for="puk1">PUK kode 1</label>
										<input name="puk1" id="puk1" type="text" class="form-control" disabled :value="mvnoAccount.mvnoSim.puk1">
									</div>

								</div>
								<div class="col">
									<div class="form-group">
										<label for="puk2">PUK kode 2</label>
										<input name="puk2" id="puk2" type="text" class="form-control" disabled :value="mvnoAccount.mvnoSim.puk2">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="form-group">
										<label for="pin1">PIN kode 1</label>
										<input name="pin1" id="pin1" type="text" class="form-control" disabled :value="mvnoAccount.mvnoSim.pin1">
									</div>
								</div>
								<div class="col">
									<div class="form-group">
										<label for="pin2">PIN kode 2</label>
										<input name="pin2" id="pin2" type="text" class="form-control" disabled :value="mvnoAccount.mvnoSim.pin2">
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</transition>
		</div>
		<ConfirmChoice v-if="wantToCancelRateplanChange" :run="shouldCancelRateplanChange">
			Er du sikker på du vil annullere skifte af abonnement?
		</ConfirmChoice>
		<ConfirmChoice v-if="wantToCloseAccount" :run="shouldCloseAccount">
			Er du sikker på du vil afmelde {{ mvnoAccount.number }}
			<br/>
			<span class="black" style="font-weight: bold; margin-top: 10px; display: block;">
				OBS. Hvis du blot vil skifte udbydder, så luk ikke dit abonnement men kontakt og informer istedet ny udbydder om skifte til dem.
			</span>
		</ConfirmChoice>
		<ConfirmChoice v-if="wantToToggleMvnoState" :run="shouldToggleMvnoState">
			Er du sikker på du vil {{ mvnoAccount.state === 'BLOCKED' ? 'genåbne' : 'spærre' }} {{ mvnoAccount.number }}
		</ConfirmChoice>
		<RatePlanModal v-if="changingRatePlan" :account="props.mvnoAccount" :cancel="() => { changingRatePlan = false }" :close="rateplanChange" :type="'MVNO'"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import RegionRow from './RegionRow.vue'
import { MvnoAccount, Region, UsageRegion } from '@/interfaces'
import { Ref, ref, defineExpose, defineProps, onMounted, ComputedRef, computed, watch } from 'vue'
import SubscriptionCard from './SubscriptionCard.vue'
import RatePlanModal from '@/components/ChangeRatePlanModal.vue'
import u from '@/utils'
import axios from 'axios'
import mvno from '@/MvnoAccounts'
import ConfirmChoice from './ConfirmChoice.vue'
import Toggle from '@/components/Toggle.vue'
import router from '@/router'
const props = defineProps<{
	mvnoAccount: MvnoAccount
	regions: Array<Region>
}>()
const goto = ref();
const open: Ref<boolean> = ref(false)
const usagePeriod: Ref<string> = ref('')
const mode: Ref<'usage' | 'settings'> = ref('usage')
const changingRatePlan: Ref<boolean> = ref(false)
const newMvnoName: Ref<string> = ref(props.mvnoAccount.name);
const wantToCancelRateplanChange: Ref<boolean> = ref(false);
const cancelingChangeRateplan: Ref<boolean> = ref(false);
const togglingMvnoState: Ref<boolean> = ref(false);
const wantToToggleMvnoState: Ref<boolean> = ref(false);
const changingMvnoName: Ref<boolean> = ref(false);
const toggleMvnoStateError: Ref<boolean> = ref(false);
const savingFeatures: Ref<boolean> = ref(false);
const featuresChanged: Ref<boolean> = ref(false);
const numberListed: Ref<boolean> = ref(false);
const wantToCloseAccount: Ref<boolean> = ref(false);
const deleteAccountError: Ref<boolean> = ref(false)
const features: Ref<{highRate: boolean, international: boolean, chargedServices: boolean }> = ref({
	highRate: false,
	international: false,
	chargedServices: false
})
const statusIcon: ComputedRef<Array<string>> = computed(() => {
	if (props.mvnoAccount.state === 'DELETING') {
		return ['fas', 'trash']
	}
	if (props.mvnoAccount.info.updating) {
		return ['fas', 'arrows-rotate'];
	}
	if (props.mvnoAccount.newRatePlan) {
		return ['fas', 'shuffle']
	}
	if (props.mvnoAccount.state === 'CREATING') {
		return ['fas', 'clock']
	}
	if (props.mvnoAccount.state === 'BLOCKED') {
		return ['fas', 'ban']
	}
	return ['fas', 'check']
})
const createAtLabel: ComputedRef<string> = computed(() => {
	if (!props.mvnoAccount.startDate) { return 'Oprettes hurtigst muligt'; }
	const d = new Date(props.mvnoAccount.startDate);
	const year = d.getFullYear();
	const month = d.toLocaleDateString('da-dk', { month: 'long' })
	const date = d.getDate() > 10 ? d.getDate() : `0${d.getDate()}`
	return `Oprettes: ${date}. ${month} ${year}`
})
const qrcode: Ref<string> = computed(() => {
	if (!props.mvnoAccount.mvnoSim.activationCode) {
		return '';
	}
	return `${u.apiurl}/tools/qr?text=${props.mvnoAccount.mvnoSim.activationCode}&size=8&margin=0`;
})
const canBlock: ComputedRef<boolean> = computed(() => {
	if (['PORTING_IN', 'PORTING_OUT'].includes(props.mvnoAccount.numberState)) {
		return false;
	}
	return true;
})


onMounted(async () => {
	usagePeriod.value = periods.value[0]
	if (!props.mvnoAccount.dataUsage && !props.mvnoAccount.voiceUsage) {
		mode.value = 'settings';
	}
	features.value = {...props.mvnoAccount.feature}
	try {
		const res = await axios.get(`${u.apiurl}/number/${props.mvnoAccount.number}`, { params: { token: u.token } });
		numberListed.value = res.data.listed;
	} catch(err) {
		console.error(err);
	}
	try {
		await shouldIOpen();
		open.value = true;
		goto.value.scrollIntoView();
		router.push('/');
	} catch(err) {

	}
})

// watch(mode, onModeChange);
// function onModeChange () {

// }
const periods: ComputedRef<string[]> = computed(() => {
	const today = new Date();
	const currMonth = today.getMonth()+1;
	const _periods = ['', '', '', '', ''];
	for (let i = 0; i < _periods.length; i++) {
		const periodDate = new Date();
		periodDate.setMonth(currMonth - i);
		_periods[i] = `${periodDate.getFullYear()}${periodDate.getMonth() < 10 ? `0${periodDate.getMonth()}` : periodDate.getMonth()}`;
	}
	return _periods;
})

const dataUsage: ComputedRef<UsageRegion[]> = computed(() => {
	if (!props.mvnoAccount.dataUsage) { return [] }
	const usage = props.mvnoAccount.dataUsage.find(o => o.month === usagePeriod.value);
	if (!usage) {
		return [];
	}
	return usage.regions;
})
const callUsage: ComputedRef<UsageRegion[]> = computed(() => {
	if (!props.mvnoAccount.voiceUsage) { return [] }
	const voiceUsage: any = props.mvnoAccount.voiceUsage.find(o => o.month === usagePeriod.value);
	if (!voiceUsage) { return []; }
	const keys = ['EuNordic', 'RestOfEurope', 'World1', 'World2', 'World3']
	const toReturn = [{
		usage: voiceUsage.usage,
		subscription: voiceUsage.subscription,
		_id: 'home'
	}];
	for(const key of keys) {
		toReturn.push({
			usage: voiceUsage[`usage${key}`],
			subscription: voiceUsage[`subscription${key}`],
			_id: key
		});
	}
	return toReturn;
})
async function shouldIOpen() {
	return new Promise((resolve, reject) => {
		const params = new URLSearchParams(location.search);
		const openAccount = params.get('openAccount');
		if (openAccount && openAccount === props.mvnoAccount._id) {
			resolve(undefined);
		} else {
			reject();
		}
	});
}
function toggleOpen() {
	open.value = !open.value
}

function resetFeatures() {
	features.value = {...props.mvnoAccount.feature};
	featuresChanged.value = false;
}

function shouldCloseAccount(doIt: boolean) {
	if (doIt) {
		deleteAccountError.value = false;
		closeAccount();
	} else {
		wantToCloseAccount.value = false;
	}
}

async function closeAccount() {
	try {
		await axios.delete(`${u.apiurl}/mvno/${props.mvnoAccount._id}`, {
			params: {
				token: u.token
			}
		})
		mvno.loadAccounts();
	} catch(err) {
		deleteAccountError.value = true;
	}
	wantToCloseAccount.value = false;
}




async function saveFeatures() {
	savingFeatures.value = true;
	try {
		await axios.post(`${u.apiurl}/number/${props.mvnoAccount.number}`, {
			_id: props.mvnoAccount.number,
			listed: numberListed.value
		}, {
			params: {
				token: u.token
			}
		})
		await axios.post(`${u.apiurl}/mvno/${props.mvnoAccount._id}`, {
			feature: {
				highRate: features.value.highRate,
				international: features.value.international
			}
		}, {
			params: {
				token: u.token
			}
		})
		mvno.loadAccounts();
		resetFeatures();
	} catch(err) {
		console.error(err);
	}
	savingFeatures.value = false;
}

function formatPeriod(period: string) {
	const year: number = parseInt(period.substring(0, 4));
	const month: number = parseInt(period.substring(4, 6));
	const date = new Date();
	date.setMonth(month-1);
	date.setFullYear(year);
	const localeDate = date.toLocaleDateString('da-DK', { month: 'long', year: 'numeric' });
	return localeDate;
}

function startChangeRatePlan() {
	changingRatePlan.value = true;
}

function rateplanChange(success: boolean) {
	changingRatePlan.value = false;
	if (success) { u.getProducts();}
}

async function updateRateplanName(newName: string) {
	changingMvnoName.value = true;
	if (!newName) { 
		changingMvnoName.value = false;
		return;
	}
	u.ratelimit('rateplan_name', async () => {
		try {
			await axios.post(`${u.apiurl}/number/${props.mvnoAccount.number}`, {
				name: newName,
				_id: props.mvnoAccount.number
			}, { params: { token: u.token } })
			changingMvnoName.value = false;
			mvno.loadAccounts()
		} catch(err) {
			console.error(err);
		}
	});
}
async function toggleMvnoState() {
	togglingMvnoState.value = true;
	toggleMvnoStateError.value = false;
	const newState = props.mvnoAccount.state === 'BLOCKED' ? 'ACTIVE' : 'BLOCKED';
	try {
		await axios.post(`${u.apiurl}/mvno/${props.mvnoAccount._id}`, {
			state: newState
		}, {
			params: {
				token: u.token
			}
		})
		mvno.loadAccounts();
		togglingMvnoState.value = false;
	} catch(err) {
		toggleMvnoStateError.value = true;
		togglingMvnoState.value = false;
	}
}
function shouldToggleMvnoState(shouldToggle: boolean) {
	if (shouldToggle) {
		toggleMvnoState();
	} else {
		togglingMvnoState.value = false;
	}
	wantToToggleMvnoState.value = false;
}
async function cancelRateplanChange() {
	cancelingChangeRateplan.value = true;
	try {
		await axios.post(`${u.apiurl}/mvno/${props.mvnoAccount._id}`, {
			newRatePlan: null
		}, { params: { token: u.token } })
		wantToCancelRateplanChange.value = false;
		cancelingChangeRateplan.value = false;
		mvno.loadAccounts();
	} catch(err) {
		cancelingChangeRateplan.value = false;
		console.error(err);
	}
}

function shouldCancelRateplanChange(shouldCancel: boolean) {
	if (shouldCancel) {
		cancelRateplanChange();
	} else {
		wantToCancelRateplanChange.value = false;
	}
}

watch(newMvnoName, updateRateplanName);
defineExpose(RegionRow)
</script>

<style scoped lang="scss">
@import '@/components/OwnedAccounts.scss';
.nameStatusIcon {
	transition-property: opacity;
	transition-timing-function: ease;
	transition-duration: .1s;
}
.list {
	.list-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 10px;
		border-bottom: 1px solid var(--light-grey);
		&:last-of-type {
			border-bottom: none;
		}
	}
}
.panel {
	border-radius: 5px;
	border: 1px solid var(--light-grey);
	overflow: hidden;
	.panel-header {
		background-color: var(--theme);
		color: white;
		padding: 5px 10px;
	}
	.panel-body {
		background-color: var(--background);
	}
	.panel-footer {
		padding: 5px 10px;
		background-color: var(--app-background);
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}
.fa-icon {
	transition-property: opacity;
	transition-duration: .1s;
	transition-timing-function: ease;
	&.v-leave-to,
	&.v-enter-from {
		opacity: 0;
	}
}
</style>
<style lang="scss">
.currentSubscription {
	button.cta {
		width: unset;
		min-width: 120px;
	}
}
.mvnoAccount {
	.subscriptionCard {
		// width: unset;
	}
}
</style>