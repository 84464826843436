import { SipAccount, Product } from '@/interfaces';
import axios from 'axios';
import { Ref, ref } from 'vue';
import u from './utils'
interface Sip {
	items: Ref<SipAccount[]>
	products: Ref<Product[]>
	loadAccounts(): Promise<boolean>
	loadProducts(): Promise<void>
}
const sip: Sip = {
	items: ref([]),
	products: ref([]),
	loadAccounts: loadAccounts,
	loadProducts: loadProducts
}

async function loadAccounts(): Promise<boolean> {
	try {
		const {sipAccounts} = await axios.get(`${u.apiurl}/sip`, { 
			params: {
				token: u.token,
				full: true
			}
		}).then(res => res.data);
		for (const account of sipAccounts) {
			const matchingProduct = sip.products.value.find((o: Product) => o._id === account.ratePlan)
			if (matchingProduct) {
				account.price = matchingProduct.price;
				account.ratePlanObject = matchingProduct;
			}
		}
		sip.items.value = sipAccounts;
		return true;
	} catch(err) {
		return false;		
	}
}

async function loadProducts(): Promise<void> {
	try {
		const { products }: { products: Array<Product> } = await axios.get(`${u.apiurl}/product?token=${u.token}&customerProducts=true&type=SIP_RATEPLAN&all=false&full=true&customer=${u.customer}`).then(res => res.data);
		products.forEach(product => {
			product.price = product.price*1.25
		})
		sip.products.value = products.sort((a, b) => a.price-b.price );
	} catch(err) {
		console.error(err);
	}
}



export default sip