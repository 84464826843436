import { MvnoAccount, Product } from '@/interfaces';
import axios from 'axios';
import { Ref, ref } from 'vue';
import u from './utils'
interface Mvno {
	items: Ref<MvnoAccount[]>
	loadAccounts(): Promise<boolean>
}
const mvno: Mvno = {
	items: ref([]),
	loadAccounts: loadAccounts
}

async function loadAccounts(): Promise<boolean> {
	try {
		const {mvnoAccounts} = await axios.get(`${u.apiurl}/mvno`, { 
			params: {
				token: u.token,
				usage: true,
				full: true
			}
		}).then(res => res.data);
		for (const account of mvnoAccounts) {
			const matchingProduct = u.products.find((o: Product) => o._id === account.ratePlan)
			if (matchingProduct) {
				account.price = matchingProduct.price;
				account.ratePlanObject = matchingProduct;
			}
		}
		mvno.items.value = mvnoAccounts;
		return true;
	} catch(err) {
		return false;		
	}
}



export default mvno