<template>
  <div class="region">
	<p class="regionName black">{{regionName}}</p>
	<div class="regionData">
		<p>{{usageLabel}}</p>
	</div>
  </div>
</template>
<script setup lang="ts">
import { Region, UsageRegion } from '@/interfaces';
import { ComputedRef, computed, defineProps } from 'vue';
const props = defineProps<{
	region: Region | string,
	usageRegions: Array<UsageRegion>,
	type: string
}>();

const regionName: ComputedRef<string> = computed(() => {
	if (typeof props.region === 'string') {
		return props.region;
	}
	const names = props.region.names;
	const name = names.find(o => o.language === 'dan' || o.language === 'da');
	if (!name) {
		return 'No name';
	}
	return name.text;
})
const usage: ComputedRef<UsageRegion | null> = computed(() => {
	const data = props.usageRegions.find(o => {
		if (typeof props.region === 'string') {
			if (o._id === props.region) {
				return o;
			}
			return false;
		} else {
			if (o._id === props.region._id) {
				return o;
			}
		}
	});
	return data || null;
})
const usageLabel: ComputedRef<string> = computed(() => {
	const type = props.type;
	let actualUsage: number | undefined = usage.value?.usage;
	if (actualUsage === undefined) {
		actualUsage = 0;
	}

	if (actualUsage === 0) {
		return '-';
	}
	const num = type === 'GB' ? convertToGB(actualUsage) : convertToHours(actualUsage);
	return `${num} ${type === 'GB' ? 'GB' : 'Timer'}`;
})

function convertToGB(num: number) {
	const convertedNum = num / 1024 / 1024 / 1024;
	return convertedNum.toFixed(2);
}
function convertToHours(num: number) {
	const convertedNum = num / 60 / 60;
	return convertedNum.toFixed(2);
}
</script>

<style lang="scss" scoped>
@import "@/style.scss";
.region {
	display: flex;
	justify-content: space-between;
	height: 35px;
	align-items: center;
	width: 100%;
	.regionData {
		display: flex;
		align-items: center;
		gap: 10px;
		@include phone {
			flex-direction: row-reverse;
		}
	}
}

</style>