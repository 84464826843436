<!-- eslint-disable vue/multi-word-component-names -->
<template>
<div :class="`toggle ${props.value ? 'on' : ''}`"></div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
const props = defineProps<{
	value: boolean
}>();
</script>

<style lang="scss" scoped>
.toggle {
	position: relative;
	height: 20px;
	width: 35px;
	background-color: white;
	border: 1px solid var(--light-grey);
	border-radius: 10px;
	transition-property: background-color;
	transition-duration: .25s;
	transition-timing-function: ease;
	cursor: pointer;
	&[disabled=true] {
		pointer-events: none !important;
		background-color: var(--light-grey);
		&.on {
			background-color: rgba(0, 181, 0, 0.5);
		}
	}
	&.on {
		background-color: rgb(0, 181, 0);
		&::after {
			left: 16px;
		}
	}
	&::after {
		transition-property: left;
		transition-duration: .3s;
		transition-timing-function: ease;
		box-sizing: border-box;
		content: '';
		position: absolute;
		top: 1px;
		left: 1px;
		height: 16px;
		width: 16px;
		border-radius: 8px;
		background: white;
		box-shadow: 0 0 1px 1px var(--light-grey);
	}
}
</style>