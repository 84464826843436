<template>
<div class="sessionTimeout">
	<div class="content">
		<div class="statusIcon">
			<font-awesome-icon class="fa-icon statusIcon cancel" :icon="['fas', 'circle-xmark']"/>
		</div>
		<div class="statusMessage" style="width: 70%; position: relative;">
			<p style="text-align: center;">Din session er udløbet</p>
		</div>
	</div>
</div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.sessionTimeout {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.statusIcon {
			height: 70px;
			width: 70px;
			margin-bottom: 20px;
			&.loading {
				animation: spinner 1s linear forwards infinite;
			}
			&.confirm {
				color: #50C878;
			}
			&.cancel {
				color: #FF5733;
			}
			&:not(.loading) {
				transition-delay: .2s;
			}
		}
		.status {
			&-enter-active,
			&-leave-active {
				transition-property: opacity;
				transition-duration: 200ms !important;
				transition-timing-function: ease;
			}
			&-enter-from,
			&-leave-to {
				opacity: 0;
				transform: scale(0.9);
			}
			&-leave-active {
				position: absolute;
			}
		}
	}
}
</style>