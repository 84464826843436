<template>
<transition :appear="true">
	<div class="rateplanOverlay">
		<div class="modal">
			<div class="rateplanTopbar">
				<span class="title">Skift abonnement på {{ account.number ? account.number : account.numbers![0] }}</span>
				<button class="exit" @click="() => {close(false)}">
					<font-awesome-icon class="fa-icon" :icon="['fas', 'xmark']" />
				</button>
			</div>
			<div class="rateplanMain">
				<span v-if="errorMessage" style="color: red;">{{ errorMessage }}</span>
				<TransitionGroup tag="div" class="rateplanList" name="rateplanList" >
					<SubscriptionCard v-for="product in filteredProducts" :action="() => {selectRatePlan(product)}" :compact="true" :custom-cta="'Vælg'" :data="product" :force-mobile="true" :no-c-t-a="!!(registration.rateplan)" :key="product._id" :simple="false" :cta-classes="''"/>
				</TransitionGroup>
				<Transition mode="out-in">
					<div v-if="registration.rateplan && !cancelingSelection" class="updateRateplanSettings">
						<span class="back" @click="cancelSelection">
							<font-awesome-icon class="fa-icon" :icon="['fas', 'chevron-left']"/>
							Annuller valg
						</span>
						<div class="form-group" v-if="props.type !== 'SIP'">
							<label>Hvornår skal abonnementet skiftes?</label>
							<div style="display: flex; gap: 10px; margin-top: 10px;">
								<button :class="`cta ${!registration.now ? 'notSelected' : ''}`" @click="registration.now = true">Nu</button>
								<button :class="`cta grow ${registration.now ? 'notSelected' : ''}`" @click="registration.now = false">Ved månedsudgang</button>
							</div>
							<span v-if="registration.now" class="tooltip">Bemærk. Dette vil betyde at der vil blive trukket for begge abonnementer i denne periode</span>
						</div>
						<div class="footer" style="margin-top: auto;">
							<button :disabled="updating || deleting" class="cta grow" @click="() => { updateRatePlan(registration.rateplan!._id, registration.now) }">
								Skift abonnement
							</button>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	</div>
</transition>
</template>

<script setup lang="ts">
import u from '@/utils'
import { defineProps, Ref, ref, ComputedRef, computed } from 'vue'
import { MvnoAccount, Product, SipAccount } from '@/interfaces'
import SubscriptionCard from './SubscriptionCard.vue';
import axios from 'axios';
import mvno from '@/MvnoAccounts';
import sip from '@/SipAccounts';
const props = defineProps<{
	type: 'MVNO' | 'SIP'
	account: MvnoAccount | SipAccount
	close(success: boolean): void
}>();
const registration: Ref<{ rateplan: Product | null, now: boolean }> = ref({
	rateplan: null,
	now: false
})
const updating: Ref<boolean> = ref(false);
const deleting: Ref<boolean> = ref(false);
const filteredProducts: ComputedRef<Array<Product>> = computed(() => {
	if (registration.value.rateplan) {
		if (props.type === 'SIP') {
			return sip.products.value.filter(o => o._id === registration.value.rateplan?._id);
		}
		return u.products.filter(o => o._id === registration.value.rateplan?._id)
	}
	if (props.type === 'SIP') {
		return sip.products.value.filter(o => o.type === 'SIP_RATEPLAN' && o._id !== props.account.ratePlanObject._id);
	}
	return u.products.filter(o => o._id !== props.account.ratePlanObject._id && o.type === 'MVNO_RATEPLAN'); 
})
const errorMessage: Ref<string | null> = ref(null);
const cancelingSelection: Ref<boolean> = ref(false);

function selectRatePlan(rateplan: Product) {
	registration.value.rateplan = rateplan;
}

async function updateRatePlan(rateplan: string, now: boolean) {
	if (updating.value) return
	try {
		await axios.post(`${u.apiurl}/${props.type === 'SIP' ? 'sip' : 'mvno'}/${props.account._id}?token=${u.token}`, {
			newRatePlan: rateplan,
			newRatePlanNow: now
		});
		if (props.type === 'SIP') {
			sip.loadAccounts();
		} else {
			mvno.loadAccounts();
		}
		props.close(true);
	} catch(err) {
		// TODO: Proper error handling
		props.close(false);
	}
}
function cancelSelection() {
	cancelingSelection.value = true;
	setTimeout(() => {
		registration.value.rateplan = null;
		cancelingSelection.value = false;
	}, 300);
}

</script>

<style lang="scss">
@import '@/style.scss';
.rateplanOverlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	transition-property: background-color;
	transition-duration: 200ms;
	transition-timing-function: ease;
	&.v-enter-from,
	&.v-leave-to {
		background-color: transparent;
		.modal {
			opacity: 0;
			transform: scale(0.9);
		}
	}
	.modal {
		transition-property: opacity, transform;
		transition-duration: 200ms;
		transition-timing-function: ease;
		background-color: white;
		width: 85%;
		height: 65%;
		border-radius: 10px;
		padding: 20px 40px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 5px 2px rgba(99, 99, 99, .5);
		max-width: 1000px;
		max-height: 800px;
		@include phone {
			width: 95%;
			padding: 10px 10px;
		}
		.rateplanTopbar {
			display: flex;
			justify-content: space-between;
			position: relative;
			align-items: center;
			padding-bottom: 5px;
			button {
				background-color: transparent;
				cursor: pointer;
				padding: 4px;
				&:hover {
					background-color: var(--app-background);
					border-radius: 50%;
				}
				.fa-icon {
					height: 20px;
					width: 20px;
				}
			}
			&:after {
				content: "";
				top: 100%;
				left: 0;
				right: 0;
				border-radius: 1px;
				border: 1px solid var(--light-grey);
				position: absolute;
			}
		}
		::-webkit-scrollbar {
			display: none;
		}
		.rateplanMain {
			overflow: scroll;
			padding: 20px 0;
			flex-grow: 1;
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			.rateplanList {
				flex-direction: column;
				gap: 10px;
				> * {
					margin-bottom: 10px;
				}
			}
			.rateplanList-move,
			.rateplanList-enter-active,
			.rateplanList-leave-active {
				transition-property: all;
				transition-timing-function: ease;
				transition-duration: 350ms;
			}
			.rateplanList-enter-active,
			.rateplanList-leave-active {
				transition-duration: 200ms;

			}
			.rateplanList-enter-from,
			.rateplanList-leave-to {
				opacity: 0;
			}
			.rateplanList-leave-active {
				position: absolute;
			}
			.updateRateplanSettings,
			.deleteRateplan {
				transition-property: opacity, transform;
				transition-duration: 300ms;
				transition-timing-function: ease;
				&.v-enter-from,
				&.v-leave-to {
					opacity: 0;
					// transform: scale(0.9);
				}
			}
			.updateRateplanSettings {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				&.v-enter-active {
					// transition-delay: 100ms;
				}
			}
			.deleteRateplan {
				flex-grow: 1;
				display: flex;
				button {
					margin-top: auto;
				}
			}
			.back {
				display: flex;
				align-items: center;
				cursor: pointer;
				font-size: 14px;
			}
			.footer {
				margin-left: auto;
			}
		}
	}
}
</style>
