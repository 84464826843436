<template>
  <div class="mvnoAccounts section">
    <h2 class="sectionTitle">Dine abonnementer</h2>
    <p class="sectionText" v-html="mvno.items.value.length > 0 || sip.items.value.length > 0 ? 'Her kan du se, hvilke abonnementer du allerede har. <br> Klik på abonnementet for at se flere oplysninger' : 'Du har ingen aktive abonnementer lige nu'"></p>
    <div class="mvnoAccountsTable" v-if="mvno.items.value.length > 0">
		<h2 class="subtitle">Mobiler</h2>
        <MvnoAccountComponent :regions="_regions.filter(o => o.network === mvnoAccount.mvnoSim.network)" :mvnoAccount="mvnoAccount" v-for="mvnoAccount in mvno.items.value" :key="mvnoAccount._id"/>
    </div>
	<div class="mvnoAccountsTable" v-if="sip.items.value.length > 0">
		<h2 class="subtitle">Fastnet</h2>
		<SipAccountComponent :sipAccount="sipAccount" v-for="sipAccount in sip.items.value" :key="sipAccount._id" />
	</div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import u from '@/utils'
import { Ref, ref, onMounted } from 'vue'
import { Region } from '@/interfaces'
import MvnoAccountComponent from './MvnoAccount.vue'
import SipAccountComponent from './SipAccount.vue'
import mvno from '@/MvnoAccounts'
import sip from '@/SipAccounts'
const _regions: Ref<Region[]> = ref([]);
onMounted(async () => {
	await getRegions();
});

async function getRegions() {
	try {
		const data = await axios.get(`${u.apiurl}/roaming/region?token=${u.token}`).then(res => res.data);
		_regions.value = data.regions;
	} catch(err) {
		// TODO: Proper error handling if regions cannot be fetched
	}
}
</script>

<style lang="scss" scoped>
@import "@/style.scss";
.mvnoAccounts {
    background: var(--background);
    border-radius: 4px;

    .mvnoAccountsTable {
        width: 80%;
        margin: 0 auto;
        padding: 40px 0 30px 0;
		@include phone {
			width: 95%;
		}
        .header {
            display: grid;
            grid-template-columns: 1fr 2fr 20px;
            padding: 0 20px;
            gap: 10px;
			@include phone {
				padding: 0 10px;
			}
			.subscription {
				@include phone {
					display: none;
				}
			}
        }
    }
}
</style>