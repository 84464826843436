<template>
<router-view v-slot="{ Component }" v-if="u.customer && u.token && !validating">
	<transition name="vueTransition" mode="out-in"> 
		<component :is="Component" />
	</transition>
</router-view>
<SessionTimeoutView v-else-if="!validating"/>
</template>

<script setup lang="ts">
import u from '@/utils';
import { Ref, onMounted, ref } from 'vue';
import SessionTimeoutView from './views/SessionTimeoutView.vue';
import mvno from './MvnoAccounts';
import sip from './SipAccounts';
const validating: Ref<boolean> = ref(true);
onMounted(async () => {
	try {
		brandDocumentTitle();
		const params = new URLSearchParams(window.location.search);
		if (params.get('token')) {
			u.token = params.get('token') ?? '';
		} else {
			if (sessionStorage.getItem('token')) {
				u.token = sessionStorage.getItem('token') ?? '';
			}
		}
		const user = await u.validate();
		sessionStorage.setItem('token', u.token);
		u.customer = user.customer;
		u.keepSession();
		await u.getProducts();
		await sip.loadProducts();
		await sip.loadAccounts();
		await mvno.loadAccounts();
		validating.value = false;
		// this.$router.push(this.$route.path);
	} catch(err) {
		u.token = '';
		u.customer = '';
		sessionStorage.removeItem('token');
		validating.value = false;
	}
})


function brandDocumentTitle() {
	switch(location.hostname) {
	case 'mobil.sundbynet.dk':
		document.title = 'Sundbynet telefoni';
		break;
	default:
		document.title = 'Privat telefoni';
	}
}


defineExpose(SessionTimeoutView);
</script>
<style lang="scss">
@import '@/style.scss';
</style>
