<template>
<div class="validation">
	<div class="content">
		<div class="statusIcon">
			<Transition name="status" mode="out-in">
				<font-awesome-icon v-if="status === 'LOADING'" class="fa-icon statusIcon loading" :icon="['fas', 'circle-notch']"/>
			</Transition>
			<Transition name="status" mode="out-in">
				<font-awesome-icon v-if="status === 'CONFIRMED'" class="fa-icon statusIcon confirm" :icon="['fas', 'circle-check']"/>
				<font-awesome-icon v-else-if="status === 'DENIED'" class="fa-icon statusIcon cancel" :icon="['fas', 'circle-xmark']"/>
			</Transition>
		</div>
		<div class="statusMessage" style="width: 70%; position: relative;">
			<Transition name="status" mode="out-in">
				<p v-if="!message" style="text-align: center;">Vent venligst mens vi behandler din anmodning</p>
				<p v-else-if="message" style="text-align: center;">{{ message }}</p>
			</Transition>
		</div>
	</div>
</div>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue';
import CriiptoAuth from '@criipto/auth-js';
import axios from 'axios';
import u from '@/utils';
import { Registration, DynamicObject, MitIDClaim } from '@/interfaces';
import router from '@/router';
import sip from '@/SipAccounts';
import mvno from '@/MvnoAccounts';
interface registrationWithType extends Registration {
	type: 'SIP' | 'MVNO'
}
const status: Ref<'LOADING' | 'CONFIRMED' | 'DENIED'> = ref('LOADING');
const message: Ref<string> = ref('');
const criiptoAuth = new CriiptoAuth({
	domain: u.mitIdDomain,
	clientID: u.mitIdApplicationId,
	store: sessionStorage,
});
async function matchCallback() {
	let createdId = '';
	try {
		const result = await criiptoAuth.redirect.match();
		const claims = result?.claims as MitIDClaim | undefined
		const registration: registrationWithType = result?.state ? JSON.parse(result.state) : null;
		if (!claims) {
			throw new Error('no_claims_from_mitid');
		}
		if (registration.type === 'SIP') {
			createdId = await createSipAccount(registration, claims);
		} else if (registration.type === 'MVNO') {
			createdId = await createMvnoAccount(registration, claims);
		} else {
			throw new Error('no_type_on_registration');
		}
		status.value = 'CONFIRMED';
	} catch(err) {
		status.value = 'DENIED';
		const params = new URLSearchParams(location.search);
		const errDesc = params.get('error_description');
		message.value = JSON.stringify(err);
		switch(errDesc) {
		case 'User cancelled':
			message.value = 'Du har afbrudt login';
			break;
		default:
			message.value = 'Noget gik galt prøv igen senere';
			break;
		}
	}
	setTimeout(() => {
		router.push(`/${createdId ? `?openAccount=${createdId}` : ''}`);
	}, 3000);
}

async function createSipAccount(userRegistration: Registration, mitIDClaim: MitIDClaim) {
	interface employee {
		_id: string
		accessLevel: 'OWNER'
	}
	await axios.get(`${u.apiurl}/pbx/settings/${u.customer}`, { params: { token: u.token } });
	const extensionsResponse = await axios.get(`${u.apiurl}/pbx/extension`, { params: { token: u.token } });
	if (extensionsResponse.data.total < 1) {
		const employees = await axios.get(`${u.apiurl}/employee`, { params: { token: u.token } });
		const employee = employees.data.employees.find((o: employee) => o?.accessLevel === 'OWNER');
		if (!employee) { throw new Error('Error when creating extension') }
		await axios.post(`${u.apiurl}/pbx/extension`, {
			customer: u.customer,
			number: '200',
			employee: employee._id,
			primary: true
		}, { params: { token: u.token } })
	}

	const customerResponse = await axios.get(`${u.apiurl}/customer/${u.customer}`, { params: { token: u.token } });
	const address = customerResponse.data.addresses.find((o: { primary: boolean }) => o.primary);
	if (!address) { throw new Error('No address was found for customer'); }


	const number = userRegistration.numberState === 'NEW' ? userRegistration.newNumber : userRegistration.existingNumber;
	const body: DynamicObject = {
		customer: u.customer,
		name: `${number}`,
		numbers: [`+45${number}`],
		highRate: false,
		international: true,
		address: address._id,
		ratePlan: userRegistration.rateplan,
		pbx: 1,
		defaultNumber: number,
		mitId: {
			uuid: mitIDClaim.uuid,
			name: mitIDClaim.name,
			authenticationDate: mitIDClaim.authenticationinstant
		}
	}
	const {data} = await axios.post(`${u.apiurl}/sip`, body, {
		params: {
			token: u.token
		}
	})
	await axios.post(`${u.apiurl}/pbx/settings/${u.customer}`, { defaultNumber: body.numbers[0], defaultVoiceAccount: data._id }, { params: { token: u.token } })
	sip.loadAccounts();
	return data._id;
}

async function createMvnoAccount(userRegistration: Registration, mitIDClaim: MitIDClaim) {
	const number = userRegistration.numberState === 'NEW' ? userRegistration.newNumber : userRegistration.existingNumber;
	const body: DynamicObject = {
		customer: u.customer,
		number: number,
		feature: {
			highRate: true,
			international: true,
			data: true,
			fiveG: true,
			chargedServices: true,
			voicemail: true,
			roaming: true,
			roamingData: true,
			mms: true
		},
		voicemail: {
			notify: 'SMS'
		},
		pbx: null,
		ratePlan: userRegistration.rateplan,
		deleteDate: null,
		mitId: {
			uuid: mitIDClaim.uuid,
			name: mitIDClaim.name,
			authenticationDate: mitIDClaim.authenticationinstant
		}
	}
	if (userRegistration.simType === 'CARD') {
		body.autoAssignSim = 'TDC';
	} else {
		body.esim = 'TDC';
	}
	if (userRegistration.numberState === 'EXISTING') {
		body.requestedPortingDate = userRegistration.activationDate;
		if (JSON.stringify(userRegistration.iccNumber).indexOf('89450') === 0) {
			body.donorSimNumber = userRegistration.iccNumber;
		} else {
			body.donorSimNumber = `89450${userRegistration.iccNumber}`;
		}
	} else {
		body.startDate = userRegistration.activationDate;
	}
	const {data} = await axios.post(`${u.apiurl}/mvno`, body, {
		params: {
			token: u.token
		}
	})
	mvno.loadAccounts();
	return data._id;
}


onMounted(() => {
	matchCallback();
});

</script>

<style lang="scss" scoped>
.validation {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.statusIcon {
			height: 70px;
			width: 70px;
			margin-bottom: 20px;
			&.loading {
				animation: spinner 1s linear forwards infinite;
			}
			&.confirm {
				color: #50C878;
			}
			&.cancel {
				color: #FF5733;
			}
			&:not(.loading) {
				transition-delay: .2s;
			}
		}
		.status {
			&-enter-active,
			&-leave-active {
				transition-property: opacity;
				transition-duration: 200ms !important;
				transition-timing-function: ease;
			}
			&-enter-from,
			&-leave-to {
				opacity: 0;
				transform: scale(0.9);
			}
			&-leave-active {
				position: absolute;
			}
		}
	}
}
</style>