import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowsRotate, faBan, faChartPie, faCheck, faChevronDown, faChevronLeft, faChevronRight, faCircleCheck, faCircleNotch, faCircleXmark, faClock, faFloppyDisk, faGear, faRandom, faRotate, faShuffle, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

library.add(
	faCheck,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faCircleNotch,
	faCheck,
	faXmark,
	faCalendar,
	faCircleCheck,
	faCircleXmark,
	faChartPie,
	faGear,
	faShuffle,
	faTrash,
	faClock,
	faBan,
	faRandom,
	faFloppyDisk,
	faRotate,
	faArrowsRotate
)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app')
