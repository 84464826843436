<template>
<div :class="`subscriptionCard ${compact ? 'compact' : ''} ${forceMobile ? 'mobile' : ''}`">
    <div class="topbar">
        <p class="small">{{data?.name}}</p>
    </div>
    <div class="main">
        <div class="primary" v-if="!justUsage">
            <p class="black callAmount">{{callAmount}}</p>
            <p class="black">{{dataAmount}}</p>
        </div>
		<div class="primary" v-else>
			<p class="black">{{ usageDescription }}</p>
		</div>
        <p>{{data?.dataDescription}}</p>
        <div class="spacer" v-if="!simple"></div>
		<div class="flexable">
			<div>
				<div class="price">
					<p class="small" v-if="!simple">Kun</p>
					<p><span>{{data?.price}}</span>kr./md.</p>
				</div>
				<p class="small" v-if="isSip">Oprettelse af fastnet inkl. IP boks 399,-</p>
				<p class="small" v-if="!simple">Binding løbende måned</p>
			</div>
			<button v-if="!noCTA" @click="action" :class="`cta ${ctaClasses ? ctaClasses : ''}`">{{customCta ? customCta : 'Læs mere'}}</button>
		</div>
    </div>
</div>
</template>
<script setup lang="ts">
import { Product } from '@/interfaces';
import { computed } from 'vue';
const props = defineProps<{
	data: Product | null,
	action(): void,
	customCta: string,
	noCTA: boolean,
	compact: boolean
	forceMobile: boolean
	simple: boolean
	ctaClasses: string | undefined
	isSip?: true
}>()
const callAmount = computed(() => {
	const homelandAmount = props.data?.subscription?.minutes?.homeland || 0;
	if (homelandAmount > 3000) {
		return 'Fri tale';
	} else if (homelandAmount < 1) {
		return 'Forbrugsafregnet tale';
	}
	return `${homelandAmount / 60} timer`;
})
const dataAmount = computed(() => {
	const data = props.data?.subscription?.data;
	if (!data) return 'Forbrugsafregnet data';
	const inGB = data / 1024;
	if (inGB >= 1) {
		return `${inGB} GB`;
	}
	return 'Forbrugsafregnet data';
})
// If subscription is just usage we do not want to show it twice so this computed value is used
const justUsage = computed(() => {
	const data = props.data?.subscription?.data;
	const homelandAmount = props.data?.subscription?.minutes?.homeland || 0;
	if (!data && (!homelandAmount || homelandAmount < 1)) {
		return true;
	} else {
		return false;
	}
})
const usageDescription = computed(() => {
	if (props.data?.type === 'SIP_RATEPLAN') {
		if (props.data?.price === 79) {
			return 'Fri tale til alle danske fastnet- og mobilnumre'
		} else {
			return 'Fri tale til alle danske fastnet- og mobilnumre og udvalgte lande'
		}
	}
	return 'Forbrugsafregnet tale og data';
})
</script>

<style scoped lang="scss">
.subscriptionCard {
    background-color: var(--background);
    width: 250px;
    border-radius: 4px;
	overflow: hidden;
	flex-shrink: 0;
    min-width: 200px;
    width: 100%;
    .topbar {
		height: 30px;
        p {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            background-color: var(--theme);
            color: white;
            width: max-content;
            width: 100%;
            text-transform: uppercase;
        }
    }
    .main {
        padding: 40px 20px;
        .primary {
            p {
                font-weight: bold;
                font-size: 25px;
            }
        }
        .price {
            margin-top: 20px;
            span {
                font-size: 50px;
                font-weight: bold;
                color: var(--title-color);
				margin-right: 15px;
            }
        }
    }
    .cta {
        padding: 0 20px;
        margin-top: 30px;
    }
	&.compact {
		.main {
			padding: 5px 10px;

			.price {
				margin-top: 10px;
				* {
					line-height: 95%;
				}
			}
		}
	}
	&.mobile {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		.topbar {
			width: 100%;
			height: max-content
		}
		.flexable {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			flex-wrap: wrap;
		}
		.main {
			width: 100%;
			.primary {
				display: flex;
				p {
					&:first-child {
					}
					&:nth-child(2) {
						&::before {
							content: ', ';
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 550px) {
	.subscriptionCard {
		.main {
			.primary {
				display: flex;
				p {
					&:first-child {
					}
					&:nth-child(2) {
						&::before {
							content: ', ';
						}
					}
				}
			}
		}
	}
}
</style>