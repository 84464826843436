<template>
  <div class="home view" v-if="u.token && u.customer">
    <div class="section">
      <h2 class="sectionTitle">Et abonnement til ethvert behov</h2>
      <p class="sectionText">Vil du have masser af data og mulighed for at roame i udlandet eller vil du bare gerne have fri tale og fri sms samt mindre datapakke? Uanset hvilket mobilabonnement du vælger, er der binding løbende måned</p>
    </div>
    <div class="section">
		<h3 class="subtitle">Mobil</h3>
		<div class="subscriptions">
			<SubscriptionCard v-for="subscription in u.products" :key="subscription._id" :force-mobile="false" :simple="false" :data="subscription" :action="() => { startCreate(subscription._id, 'MVNO') }" :custom-cta="''" :no-c-t-a="false" :compact="false" :cta-classes="''"/>
		</div>
		<h3 v-if="sip.products.value.length > 0" class="subtitle" style="margin-top: 20px;">Fastnet</h3>
		<div class="subscriptions">
			<SubscriptionCard v-for="product in sip.products.value" :key="product._id" :force-mobile="false" :simple="false" :data="product" :action="() => { startCreate(product._id, 'SIP') }" :custom-cta="''" :no-c-t-a="false" :compact="false" :cta-classes="''" :isSip="true"/>
		</div>
    </div>
    <owned-subscriptions/>
  </div>
</template>
<script setup lang="ts">
import SubscriptionCard from '@/components/SubscriptionCard.vue';
import OwnedSubscriptions from '@/components/OwnedSubscriptions.vue';
import u from '@/utils';
import router from '@/router/index'
import sip from '@/SipAccounts'


function startCreate(id: string, type: 'SIP' | 'MVNO') {
	router.push(`/${id}?type=${type}`);
}
</script>

<style lang="scss" scoped>
.home {
	.subscriptions {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		justify-content: center;
		align-items: center;
		gap: 10px;

		&>* {
			flex-grow: 1;
		}
	}
}
</style>