import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CreateView from '../views/CreateView.vue'
import ValidationView from '../views/ValidationView.vue'
import SessionTimeoutView from '../views/SessionTimeoutView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/validatecallback',
		name: 'MitID validation',
		component: ValidationView
	},
	{
		path: '/:id',
		name: 'create',
		component: CreateView
	},
	{
		path: '/session',
		name: 'session timeout',
		component: SessionTimeoutView
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
