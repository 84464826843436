<template>
	<div :class="`account ${open ? 'open' : ''}`" :ref="'goto'">
		<div class="accountRow" @click="toggleOpen">
			<div class="statusIcon">
				<font-awesome-icon class="fa-icon" :icon="statusIcon" />
			</div>
			<p>{{ props.sipAccount.name }} {{ props.sipAccount.numbers[0] }}</p>
			<font-awesome-icon :class="`fa-icon burger ${open ? 'open' : ''}`" :icon="['fas', 'chevron-down']"/>
			<div class="" style="width: 100%; display: flex; flex-direction: column;">
				<span class="small" v-if="!props.sipAccount.active">Spærret</span>
			</div>
		</div>
		<div class="content" v-if="open">
			<div class="separator"></div>
			<div class="topbar">
				<button :class="`${mode === 'usage' ? 'active' : ''}`" @click="() => mode = 'usage'">
					<font-awesome-icon class="fa-icon" :icon="['fas', 'chart-pie']"/>
					Forbrug
				</button>
				<button :class="`${mode === 'settings' ? 'active' : ''}`" @click="() => mode = 'settings'">
					<font-awesome-icon class="fa-icon" :icon="['fas', 'gear']" />
					Indstillinger
				</button>
				<button class="close" @click="toggleOpen()">
					Luk
					<font-awesome-icon class="fa-icon" :icon="['fas', 'circle-xmark']"/>
				</button>
			</div>
			<div class="inner" ref="content">
				<Transition mode="out-in">
					<div class="detailsMain" v-if="mode === 'usage'">
						<div class="dateSelector">
							<p>Periode: </p>
							<select class="form-control" name="usagePeriod" id="usagePeriod" v-model="usagePeriod">
								<option :value="period.toISOString()" v-for="period in periods" :key="period.toISOString()" >{{formatPeriod(period)}}</option>
							</select>
						</div>
						<div class="regions">
							<div class="region">
								<p class="regionName black">I alt for perioden</p>
								<div class="regionData">
									<p>{{voiceUsage}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="detailsMain" v-else-if="mode === 'settings'">
						<div class="row">
							<div class="col" style="min-width: 250px;">
								<SubscriptionCard class="currentSubscription" :simple="true" :action="startChangeRatePlan" :forceMobile="true" :compact="true" :customCta="'Skift abonnement'" :noCTA="!sipAccount.active || sipAccount.newRatePlan ? true : false" :data="sip.products.value.find(o => o._id === sipAccount.ratePlanObject._id) || null" :ctaClasses="''"/>
								<div v-if="sipAccount.newRatePlan" style="margin-top: 20px;" class="form-group">
									<label for="">Fra næste måned benyttes</label>
									<SubscriptionCard v-if="sip.products.value.find(o => o._id === sipAccount.newRatePlan) && sipAccount.newRatePlan" class="newSubscription" :simple="true" :action="() => {wantToCancelRateplanChange = true}" :force-mobile="true" :compact="true" :custom-cta="'Annuller skifte'" :noCTA="false" :data="sip.products.value.find(o => o._id === sipAccount.newRatePlan) || null" :ctaClasses="'destructive grow notSelected'"/>
								</div>
								<div class="form-group">
									<label for="subscriptionName">Navn</label>
									<div class="input-group">
										<input type="text" :disabled="!props.sipAccount.active" name="subscriptionName" id="subscriptionName" v-model="newSipName" class="form-control">
										<div style="position: relative; padding-right: 5px;">
											<font-awesome-icon class="fa-icon spinner nameStatusIcon" :style="{ 'opacity': changingSipName ? 1 : 0 }" :icon="['fas', 'circle-notch']"/>
										</div>
									</div>
									<span class="tooltip">Navn på bruger af telefonnummeret: {{ props.sipAccount.numbers[0] }}</span>
								</div>
								<div class="form-group">
									<button :class="`cta grow notSelected destructive`" @click="wantToCloseSipAccount = true;">
										Afmeld {{ sipAccount.numbers[0] }}
									</button>
									<span class="tooltip red" v-if="deleteSipAccountError">Der skete en uventet fejl, nummeret er ikke slettet</span>
								</div>
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</div>
		<ConfirmChoice v-if="wantToCancelRateplanChange" :run="shouldCancelRateplanChange">
			Er du sikker på du vil annullere skifte af abonnement?
		</ConfirmChoice>
		<ConfirmChoice v-if="wantToCloseSipAccount" :run="shouldCloseSipAccount">
			Er du sikker på du vil afmelde {{ props.sipAccount.numbers[0] }}
		</ConfirmChoice>
		<RatePlanModal v-if="changingRatePlan" :account="props.sipAccount" :cancel="() => { changingRatePlan = false }" :close="rateplanChange" :type="'SIP'"/>
	</div>
</template>

<script setup lang="ts">
import { ComputedRef, Ref, computed, defineProps, onMounted, ref, watch } from 'vue';
import {SipAccount} from '@/interfaces';
import SubscriptionCard from './SubscriptionCard.vue';
import RatePlanModal from '@/components/ChangeRatePlanModal.vue';
import u from '@/utils';
import sip from '@/SipAccounts';
import axios from 'axios';
import ConfirmChoice from './ConfirmChoice.vue';
import router from '@/router';
const props = defineProps<{
	sipAccount: SipAccount
}>()
const goto = ref();
const open: Ref<boolean> = ref(false)
const mode: Ref<'usage' | 'settings'> = ref('usage')
const usagePeriod: Ref<string> = ref('')
const changingRatePlan: Ref<boolean> = ref(false)
const wantToCancelRateplanChange: Ref<boolean> = ref(false)
const newSipName: Ref<string> = ref(props.sipAccount.name)
const cancelingChangeRateplan: Ref<boolean> = ref(false)
const changingSipName: Ref<boolean> = ref(false)
const wantToCloseSipAccount: Ref<boolean> = ref(false)
const deleteSipAccountError: Ref<boolean> = ref(false)
const statusIcon: ComputedRef<Array<string>> = computed(() => {
	if (props.sipAccount?.newRatePlan) {
		return ['fas', 'shuffle']
	}
	if (!props.sipAccount.active) {
		return ['fas', 'ban']
	}
	return ['fas', 'check']
})
const periods: ComputedRef<Date[]> = computed(() => {
	const today = new Date();
	today.setDate(1);
	today.setHours(0, 0, 0, 0);
	const currMonth = today.getMonth();
	const _periods = [];
	for (let i = 0; i < 12; i++) {
		const periodDate = new Date(today);
		periodDate.setMonth(currMonth - i);
		_periods[i] = periodDate;
	}
	return _periods;
})
const voiceUsage: ComputedRef<string> = computed(() => {
	const usageFromAccount = props.sipAccount.voiceUsage.filter(o => {
		const from = new Date(usagePeriod.value)
		from.setDate(1);
		const to = new Date(usagePeriod.value);
		to.setDate(1);
		to.setMonth(to.getMonth() + 1);
		const date = new Date(o.date)
		if (from < date && to > date) {
			return o;
		}
	})
	const usageNums = usageFromAccount.map(o => o.secsOutHomeland);
	const secondsOfUsage = usageNums.reduce((partialSum, a) => partialSum + a, 0);
	return `${(secondsOfUsage/60/60).toFixed(2)} Timer`;
})
onMounted(async () => {
	const today = new Date();
	today.setDate(1);
	today.setHours(0, 0, 0, 0);
	usagePeriod.value = today.toISOString();

	try {
		await shouldIOpen();
		open.value = true;
		goto.value.scrollIntoView();
		router.push('/');
	} catch(err) {

	}
});
async function shouldIOpen() {
	return new Promise((resolve, reject) => {
		const params = new URLSearchParams(location.search);
		const openAccount = params.get('openAccount');
		if (openAccount && openAccount === props.sipAccount._id) {
			resolve(undefined);
		} else {
			reject();
		}
	});
}

function shouldCloseSipAccount(doIt: boolean) {
	if (doIt) {
		closeSipAccount();
		return;
	}
	wantToCloseSipAccount.value = false;
}

async function closeSipAccount() {
	try {
		await axios.delete(`${u.apiurl}/sip/${props.sipAccount._id}`, {
			params: {
				token: u.token
			}
		})
		sip.loadAccounts();
	} catch(err) {
		deleteSipAccountError.value = true;
	}
	wantToCloseSipAccount.value = false;
}



function toggleOpen() {
	open.value = !open.value
}

function formatPeriod(period: Date) {
	const localeDate = period.toLocaleDateString('da-DK', { month: 'long', year: 'numeric' });
	return localeDate;
}

function rateplanChange(success: boolean) {
	changingRatePlan.value = false;
	if (success) { sip.loadProducts();}
}
function startChangeRatePlan() {
	changingRatePlan.value = true;
}
async function updateRateplanName(newName: string) {
	changingSipName.value = true;
	u.ratelimit('rateplan_name', async () => {
		if (!newName) { return; }
		try {
			await axios.post(`${u.apiurl}/sip/${props.sipAccount._id}`, {
				name: newName
			}, { params: { token: u.token } })
			changingSipName.value = false;
			sip.loadAccounts()
		} catch(err) {
			console.error(err);
		}
	});
}
function shouldCancelRateplanChange(shouldCancel: boolean) {
	if (shouldCancel) {
		cancelRateplanChange();
	} else {
		wantToCancelRateplanChange.value = false;
	}
}
async function cancelRateplanChange() {
	cancelingChangeRateplan.value = true;
	try {
		await axios.post(`${u.apiurl}/sip/${props.sipAccount._id}`, {
			newRatePlan: null
		}, { params: { token: u.token } })
		wantToCancelRateplanChange.value = false;
		cancelingChangeRateplan.value = false;
		sip.loadAccounts();
	} catch(err) {
		cancelingChangeRateplan.value = false;
		console.error(err);
	}
}

watch(newSipName, updateRateplanName);
</script>

<style lang="scss" scoped>
@import '@/components/OwnedAccounts.scss';
.nameStatusIcon {
	transition-property: opacity;
	transition-timing-function: ease;
	transition-duration: .1s;
}
.region {
	display: flex;
	justify-content: space-between;
	height: 35px;
	align-items: center;
	width: 100%;
	.regionData {
		display: flex;
		align-items: center;
		gap: 10px;
		@include phone {
			flex-direction: row-reverse;
		}
	}
}
</style>