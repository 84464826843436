<template>
    <div class="create view">
        <div class="step">
            <div class="section">
                <h2 class="sectionTitle">{{ subscription?.name }}</h2>
				<p class="sectionText" v-if="subscription && sipDescription" v-html="sipDescription"></p>
            </div>
            <div class="box">
                <SubscriptionCard :data="subscription" :noCTA="true" :compact="false" :simple="false" :forceMobile="false" :action="() => {}" :class="`${step > 0 ? 'faded' : ''}`" :customCta="''"  :ctaClasses="''" :isSip="type === 'SIP' ? true : undefined"/>
                <div class="content">
					<div class="inner">
						<transition :name="`${direction == 'back' ? 'stepBack' : 'step'}`">
							<div class="step number" v-if="step === 1">
								<h3 class="heading">Nummer</h3>
								<p class="description">Vælg, om du vil bruge dit eksisterende nummer eller oprette et nyt</p>
								<form>
									<div class="form-group">
										<div class="flex">
											<input type="radio" name="newNumberMode" id="existing" value="EXISTING" v-model="registration.numberState">
											<label for="existing">Jeg vil gerne bruge mit eksisterende telefonnummer</label>
										</div>
										<div class="input-group" style="margin-bottom: 10px;">
											<span class="prefix">+45</span>
											<input type="tel" placeholder="Eksisterende nummer" v-model="registration.existingNumber">
											<div style="position: relative; padding-right: 5px;">
												<transition name="statusIcon" mode="out-in">
													<font-awesome-icon v-if="['LOADING', ''].includes(existingNumberStatus)" class="fa-icon spinner" :style="{ 'opacity': existingNumberStatus === '' ? 0 : 1 }" :icon="['fas', 'circle-notch']"/>
													<font-awesome-icon v-else-if="existingNumberStatus === 'CONFIRMED'" class="fa-icon green" :icon="['fas', 'check']"/>
													<font-awesome-icon v-else-if="existingNumberStatus === 'DENIED'" class="fa-icon red" :icon="['fas', 'xmark']"/>
												</transition>
											</div>
										</div>
									</div>
									<div class="form-group" style="margin-bottom: 10px;" v-if="existingNumberStatus === 'CONFIRMED' && type === 'MVNO'">
										<label for="iccNumber">Nuværende SIM nummer (ICC)</label>
										<div class="input-group">
											<span class="prefix">89450</span>
											<input type="text" class="form-control" v-model="registration.iccNumber">
											<div style="position: relative; padding-right: 5px;">
												<transition name="statusIcon" mode="out-in">
													<font-awesome-icon v-if="validIccNumber" class="fa-icon green" :icon="['fas', 'check']"/>
													<font-awesome-icon v-else-if="registration.iccNumber && !validIccNumber" class="fa-icon red" :icon="['fas', 'xmark']"/>
												</transition>
											</div>
										</div>
										<span class="tooltip">Her ser du hvor du finder dit icc nummer</span>
									</div>
									<div class="form-group">
										<div class="flex">
											<input type="radio" name="newNumberMode" id="newNumber" value="NEW" v-model="registration.numberState">
											<label for="newNumber">Jeg vil gerne oprette et nyt telefonnummer</label>
										</div>
										<div class="input-group grey">
											<span class="prefix">+45</span>
											<input type="tel" placeholder="Nyt nummer" disabled class="form-control" v-model="registration.newNumber">
											<div style="position: relative; padding-right: 5px;" @click="handleGetRandomNewNumber">
												<transition name="statusIcon" mode="out-in">
													<font-awesome-icon v-if="gettingNewNumbers" class="fa-icon spinner" :icon="['fas', 'circle-notch']"/>
													<font-awesome-icon v-else-if="!gettingNewNumbers && registration.numberState === 'NEW'" class="fa-icon randomNumber" :icon="['fas', 'random']"/>
												</transition>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div class="step" v-else-if="step === 2">
								<h3 class="heading">Levering</h3>
								<p class="description">Her skal du vælge, om du skal bruge et fysisk sim-kort eller er E-sim. Hvis du vælger et fysisk sim-kort, bliver det leveret til dig inden for 7 dage. Et E-sim er klar til brug allerede i morgen.</p>
								<form>
									<div class="form-group">
										<div class="flex">
											<input type="radio" name="simcardType" id="simcard" value="CARD" v-model="registration.simType">
											<label for="simcard">Jeg vil gerne have et fysisk sim-kort tilsendt</label>
										</div>
									</div>
									<div class="form-group">
										<div class="flex">
											<input type="radio" name="simcardType" id="e-sim" value="E" v-model="registration.simType">
											<label for="e-sim">Jeg vil gerne have et E-sim</label>
										</div>
									</div>
									<div class="form-group">
										<p>Vil du ønske en dato for aktivering</p>
										<label class="input-group activationDate" for="activationDate">
											<font-awesome-icon class="fa-icon" :icon="['fa-regular', 'fa-calendar']"/>
											<select name="activationDate" id="activationDate" v-model="registration.activationDate">
												<option :value="null">Vælg ønskede dato</option>
												<option v-for="date in availableDates" :key="date.date.toISOString()" :value="date.date">{{ date.label }}</option>
											</select>
										</label>
									</div>
								</form>
							</div>
							<div class="step" v-else-if="step === 3">
								<h3 class="heading">Godkend bestilling</h3>
								<div class="confirmation">
									<p>Du har valgt at oprette {{ numberType }} <span class="black">+45{{phonenumber}}</span></p>
									<p v-if="type !== 'SIP'">Du har valgt at få {{simtypeString}}</p>
								</div>
								<form>
									<p class="formDescription">Indtast dine oplysninger og godkend bestillingen.</p>
									<div class="userInfo">
										<div class="input-group" id="nameField">
											<input type="text" name="name" id="name" v-model="registration.user.name" placeholder="Fornavn og efternavn" class="form-control">
										</div>
										<div class="input-group" id="address">
											<input type="text" id="address" placeholder="Vej" v-model="registration.user.address.address" class="form-control">
										</div>
										<div class="input-group" id="zip">
											<input type="text" id="zip" placeholder="Postnr" v-model="registration.user.address.zipcode" class="form-control">
										</div>
										<div class="input-group" id="city">
											<input type="text" id="city" placeholder="By" v-model="registration.user.address.city" class="form-control">
										</div>
										<div class="input-group" id="email">
											<input type="text" id="email" placeholder="Email-adresse" v-model="registration.user.email" class="form-control">
										</div>
									</div>
									<div class="flex termsContainer">
										<input type="checkbox" name="Terms" id="Terms" required style="margin-right: 10px;" v-model="registration.terms">
										<label for="Terms">Jeg har læst og accepteret <a target="_blank" href="/sundbynet_betingelser.pdf">handelsbetingelserne</a></label>
									</div>
									
								</form>
							</div>
						</transition>
					</div>
					<div class="actionButtons">
						<transition>
							<button class="cta" v-if="step > 1" @click="prevStep">
								<font-awesome-icon class="fa-icon" :icon="['fas', 'chevron-left']"/>
								Forrige
							</button>
						</transition>
						<div style="position: relative;">
							<Transition name="mitIDTransition">
								<button class="cta" @click="nextStep" v-if="step < 3" :disabled="!canGoNext">
									Næste
									<font-awesome-icon class="fa-icon" :icon="['fas', 'chevron-right']"/>
								</button>
								<button v-else @click="handleMitID" :disabled="!canGoNext" class="cta mitID"><span style="width: max-content; flex-shrink: 0;">Godkend med</span> <img id="mitID" src="@/assets/MitID_LOGO_CLEAN_WHITE.png" alt=""></button>
							</Transition>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Product, Registration, stepdirection, existingNumberStatus } from '@/interfaces';
import { ComputedRef, Ref, computed, nextTick, onMounted, ref, watch } from 'vue';
import CriiptoAuth from '@criipto/auth-js';
import axios from 'axios';
import u from '@/utils';
import router from '@/router';
import SubscriptionCard from '@/components/SubscriptionCard.vue';
import sip from '@/SipAccounts';
const step: Ref<number> = ref(1);
const cooldown: Ref<number> = ref(0);
const direction: Ref<stepdirection> = ref('next');
const existingNumberStatus: Ref<existingNumberStatus> = ref('');
const gettingNewNumbers: Ref<boolean> = ref(false);
const subscription: Ref<Product | null> = ref(null);
const type: Ref<'MVNO' | 'SIP'> = ref('MVNO');
const registration: Ref<Registration> = ref({
	activationDate: null,
	existingNumber: null,
	simType: 'CARD',
	numberState: 'EXISTING',
	newNumber: null,
	user: {
		name: '',
		address: {
			address: '',
			zipcode: '',
			city: ''
		},
		email: ''
	},
	iccNumber: null,
	terms: false,
	rateplan: router.currentRoute.value.params.id,
	type: null
})
const sipDescription:Ref<string> = ref('');

onMounted(async () => {
	const params = new URLSearchParams(location.search);
	const paramType = params.get('type');
	if (paramType === 'MVNO') {
		type.value = 'MVNO';
	} else {
		type.value = 'SIP';
	}

	try {
		const productId = router.currentRoute.value.params.id;
		const { data } = await getSubscription((productId as string));
		subscription.value = data;
		if (type.value === 'SIP') {
			if (subscription.value?.price === 79) {
				sipDescription.value = 'Nummerleje et telefonnummer<br/>Fri tale til alle danske fastnet- og mobilnumre<br/><span style="font-size: 12px;">Special og højtakseret numre undtaget.</span>'
			} else {
				sipDescription.value = 'Nummerleje et telefonnummer, Fritale til følgende lande<br/><strong>Fastnetnumre i:</strong><br/><span style="font-size: 14px">Sverige, Norge, Tyskland, Belgien, Luxemburg, Storbritannien, Irland, Østrig, Schweiz, Italien, Frankrig, Spanien, Portugal, Malta, Grækenland, Ungarn, Australien og New Zealand.</span><br/><span style="display: block;margin-top: 5px; font-weight: bold">Fastnet- og mobilnumre i:</span> <span style="font-size: 14px;">Danmark, USA, Canada og Kina.</span><br/><br/><span style="font-size: 12px;">Special og højtakseret numre undtaget.</span>'
			}
		}
	} catch(err) {
		alert('An error happened');
		console.error(err);
	}
});

const numberType: ComputedRef<string> = computed(() => {
	if (type.value === 'SIP') {
		return 'fastnetnummeret';
	}
	return 'telefonnummeret';
});

const availableDates = computed(() => {
	const datesToReturn: {
		date: Date
		label: string
	}[] = [];
	let fromDate = null;
	const today = new Date();
	const toDate = new Date(today.getFullYear(), today.getMonth()+1, today.getDate());
	if (registration.value.simType === 'E') {
		const d = new Date();
		d.setDate(d.getDate() + 1);
		d.setHours(0, 0, 0, 0);
		fromDate = d;
	} else {
		const d = new Date();
		d.setDate(d.getDate() + 7);
		d.setHours(0, 0, 0, 0);
		fromDate = d;
	}
	while(fromDate <= toDate) {
		datesToReturn.push({
			date: new Date(fromDate),
			label: getDateLabel(fromDate)	
		})
		fromDate.setDate(fromDate.getDate()+1);
	}

	return datesToReturn;
});
const canGoNext: ComputedRef<boolean> = computed(() => {
	switch(step.value) {
	case 0:
		return true;
	case 1:
		if (registration.value.numberState === 'EXISTING' && !registration.value.existingNumber) { return false; }
		if (registration.value.numberState === 'EXISTING' && (existingNumberStatus.value !== 'CONFIRMED' || ((!registration.value.iccNumber || !validIccNumber.value) && type.value === 'MVNO'))) { return false; }
		if (registration.value.numberState === 'NEW' && !registration.value.newNumber) { return false; }
		return true;
	case 2:
		if (!registration.value.simType) { return false; }
		return true;
	case 3:
		if (!registration.value.terms) { return false; }
		if ([registration.value.user.name, registration.value.user.address.address, registration.value.user.address.city, registration.value.user.address.zipcode, registration.value.user.email].includes('')) {
			return false;
		}
		return true;
	default:
		return false;
	}
});
const validIccNumber: ComputedRef<boolean> = computed(() => {
	let localIccNumber = '';
	if (JSON.stringify(registration.value.iccNumber).indexOf('89450') === 0) {
		localIccNumber = JSON.stringify(registration.value.iccNumber);
	} else {
		localIccNumber = `89450${JSON.stringify(registration.value.iccNumber)}`;
	}
	if (localIccNumber.length < 16 || localIccNumber.length > 23) {
		return false;
	}
	return true;
})
const phonenumber: ComputedRef<string | number> = computed(() => {
	if (registration.value.numberState === 'EXISTING') {
		return registration.value.existingNumber || 0;
	}
	return registration.value.newNumber || 0;
});
const simtypeString: ComputedRef<string> = computed(() => {
	if (registration.value.simType === 'CARD') {
		return ' et fysisk sim-kort';
	}
	return ' E-sim'
});

async function getSubscription(productId: string) {
	const product = u.products.find(o => o._id === productId);
	if (product) { return { data: product } }
	const sipProduct = sip.products.value.find(o => o._id === productId);
	if (sipProduct) { return { data: sipProduct } }
	return axios.get(`${u.apiurl}/product/${productId}?token=${u.token}`);
}

function getDateLabel(_date: Date) {
	const date = new Date(_date);
	return `${u.twoDigit(date.getDate())}/${u.twoDigit(date.getMonth()+1)}-${date.getFullYear()}`;
}

async function handleNewNumber() {
	try {
		if (registration.value.numberState === 'EXISTING') { return; }
		gettingNewNumbers.value = true;
		const number = await getNewNumber();
		registration.value.newNumber = number;
		gettingNewNumbers.value = false;
	} catch(err) {
		// TODO: Proper error handling
		console.error(err);
	}
}
async function handleGetRandomNewNumber() {
	if (gettingNewNumbers.value || registration.value.numberState !== 'NEW') {return;}
	gettingNewNumbers.value = true;
	try {
		console.log('getting random')
		const number = await getNewNumber(true);
		registration.value.newNumber = number;
	} catch(err) {
		console.error(err);
	}
	gettingNewNumbers.value = false;
}
async function handleExistingNumberInput() {
	if (!registration.value.existingNumber) {return;}
	existingNumberStatus.value = 'LOADING';
	ratelimit(() => {
		if (registration.value.existingNumber) {
			checkNumber(registration.value.existingNumber);
		}
	})
}
async function nextStep() {
	direction.value = 'next';
	nextTick(async () => {
		try {
			switch(step.value) {
			case 0:
				step.value = 1;
				break;
			case 1:
				await validateStep1();
				if (type.value === 'SIP') {
					step.value = 3;
				} else {
					step.value = 2;
				}
				break;
			case 2:
				await validateStep2();
				step.value = 3;
			}
		} catch(err) {
		}
	})
}
function prevStep() {
	if (step.value < 2) return;
	direction.value = 'back';
	nextTick(() => {
		if (step.value === 3 && type.value === 'SIP') {
			step.value = 1;
		} else {
			step.value--;
		}
	})
}
async function validateStep1(): Promise<string | null> {
	return new Promise((resolve, reject) => {
		if (!registration.value.numberState) {
			return reject('numberState');
		}
		if (registration.value.numberState === 'NEW' && !registration.value.newNumber) {
			return reject('newNumber');
		}
		if (registration.value.numberState === 'EXISTING' && ((!registration.value.existingNumber || !registration.value.iccNumber) && type.value === 'MVNO')) {
			return reject('existingNumber');
		}
		resolve(null);
	});
}
async function validateStep2(): Promise<string | null> {
	return new Promise((resolve, reject) => {
		if (!registration.value.simType) { return reject('simType'); }
		resolve(null);
	});
}
function handleMitID() {
	const criiptoAuth = new CriiptoAuth({
		domain: u.mitIdDomain,
		clientID: u.mitIdApplicationId,
		store: sessionStorage,
				
	});
	const number = registration.value.numberState === 'EXISTING' ? registration.value.existingNumber : registration.value.newNumber;
	registration.value.type = type.value;
	criiptoAuth.redirect.authorize({
		redirectUri: u.mitIdRedirectURL,
		state: JSON.stringify(registration.value),
		loginHint: `action:approve message:${btoa(`Godkend ${registration.value.numberState === 'EXISTING' ? 'overdragelse' : 'oprettelse'} af telefonnummer: +45 ${number}`)}`
	})
}
async function ratelimit(func: () => void) {
	if (cooldown.value) {
		clearTimeout(cooldown.value);
	}
	cooldown.value = setTimeout(() => {
		func();
		cooldown.value = 0;
	}, 300);
}
async function checkNumber(num: number) {
	const acceptedStatuses = ['RESERVED', 'IN_PORTING_THIS_ACCOUNT', 'IN_USE_THIS_ACCOUNT', 'IN_USE_THIS_ACCOUNT', 'IN_USE_OTHER_ACCOUNT', 'PORTING_REQUIRED'];
	try {
		const { data } = await axios.post(`${u.apiurl}/${type.value === 'SIP' ? 'sip' : 'mvno'}/check/${type.value === 'SIP' ? 'numbers' : 'number'}?token=${u.token}`, {
			number: `+45${num}`,
			customer: u.customer,
			numbers: [`+45${num}`]
		})
		if (type.value === 'MVNO') {
			if (acceptedStatuses.includes(data.status)) {
				existingNumberStatus.value = 'CONFIRMED';
			} else {
				existingNumberStatus.value = 'DENIED';
			}
		} else if (type.value === 'SIP') {
			console.log(data);
			const number = data[0];
			if (acceptedStatuses.includes(number.status)) {
				existingNumberStatus.value = 'CONFIRMED';
			} else {
				existingNumberStatus.value = 'DENIED';
			}
		} else {
			existingNumberStatus.value = 'DENIED';
		}
	} catch(err) {
		existingNumberStatus.value = 'DENIED';
		if (err === 'ratelimit') {
			return;
		}
	}
}
interface NumberResponse {
	state: string
	type: string
	_id: string
}
async function getNewNumber(random?: boolean): Promise<string> {
	let available: {numbers: Array<NumberResponse>} = {
		numbers: []
	};
	if (!random) {
		available = await axios.get(`${u.apiurl}/number`, {
			params: {
				token: u.token,
				limit: 1,
				offset: 0,
				list: type.value === 'MVNO' ? 'MVNO_AVAILABLE_TDC' : 'SIP_AVAILABLE'
			}
		}).then(res => res.data);
	}
	if (!random && available.numbers && available.numbers.length > 0) {
		let number = '';
		if (type.value === 'SIP') {
			const filteredNumbers = available.numbers.filter(o => o._id.indexOf('+4570') !== 0);
			if (filteredNumbers.length > 0) number = filteredNumbers[0]._id;
		} else if (type.value === 'MVNO') {
			const _number = available.numbers[0];
			number = _number._id;
		}
		if (number) {
			return number.replace('+45', '');
		}
	}
	const res: { numbers: NumberResponse[] } = await axios.get(`${u.apiurl}/number?token=${u.token}&limit=50&offset=0&list=${type.value === 'MVNO' ? 'MVNO_PURCHASE_TDC' : 'SIP_PURCHASE'}`).then(res => res.data);
	let number = null;
	if (random) {
		const max = res.numbers.length-1;
		const index = Math.floor(Math.random() * (max + 1));
		number = res.numbers[index];
	} else {
		if (type.value === 'SIP') {
			const applicableNumbers = res.numbers.filter(o => o._id.indexOf('+4570') !== 0);
			if (applicableNumbers.length > 0) {
				number = applicableNumbers[0]
			} else {
				return '';
			}
		} else {
			number = res.numbers[0];
		}
	}
	if (number) {
		return number._id.replace('+45', '');
	}
	throw new Error();
}
watch(() => registration.value.existingNumber, () => {
	handleExistingNumberInput();
})
watch(() => registration.value.numberState, (newV) => {
	if (newV === 'NEW' && !registration.value.newNumber) {
		handleNewNumber();
		registration.value.existingNumber = null;
		existingNumberStatus.value = '';
	}
})
watch(() => registration.value.simType, () => {
	registration.value.activationDate = null;
})

</script>

<style lang="scss" scoped>
@import '@/style.scss';
.view {
	padding-bottom: 5vh;
	.box {
		background-color: var(--background);
		display: flex;
		width: 80%;
		margin: 0 auto;
		height: 500px;
		max-width: 1000px;
		@include phone {
			width: 95%;
			flex-direction: column;
			height: max-content;
			height: 90vh;
		}
		& > *:first-child {
			width: 300px;
			max-width: 35%;
			flex-shrink: 0;
			@include phone {
				max-width: 100%;
				width: 100%;
			}
		}
		.subscriptionCard {
			box-shadow: 0 0 3px 2px var(--light-grey);
			&.faded {
				opacity: 0.8;
			}
		}
		.step {
			transition: all .45s ease;
			&-enter-from {
				transform: translateX(300px) scale(.9) !important;
				opacity: 0 !important;
			}
			&Back-enter-from {
				transform: translateX(-300px) scale(.9) !important;
				opacity: 0 !important;
			}

			&-leave-to {
				transform: translateX(-300px) scale(0.9) !important;
				opacity: 0 !important;
			}
			&Back-leave-to {
				transform: translateX(300px) scale(0.9) !important;
				opacity: 0 !important;
			}
			&Back-leave-active,
			&-leave-active {
				position: absolute;
				left: 20px;
				right: 20px;
				@include phone {
					left: 10px;
					right: 10px;
				}
			}
			&.number {
				.input-group {
					margin: 5px 0;
				}
				input[type=radio] {
					margin-right: 10px;
				}
			}

			.heading {
				font-size: 25px;
				color: var(--title-color);
				margin-bottom: 10px;
			}
			.description {
				margin-bottom: 10px;
				font-size: 16px;
			}
			.userInfo {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: repeat(4, 40px);
				gap: 10px 20px;
				grid-template-areas: "name name name" "address address address" "zip city city" "email email email";
				margin: 20px 0;
				.input-group {
					width: 100%;
				}
				#nameField {
					grid-area: name;
				}
				#address {
					grid-area: address;
				}
				#number {
					grid-area: number;
				}
				#zip {
					grid-area: zip;
				}
				#city {
					grid-area: city;
				}
				#email {
					grid-area: email;
				}
			}
		}
		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			overflow: hidden;
			position: relative;
			padding: 20px;
			@include phone {
				height: max-content;
				padding: 10px;
			}
			.inner {
				flex-grow: 1;
				@include phone {
					height: 300px;
				}
			}
			.actionButtons {
				display: flex;
			}
			.cta {
				overflow: hidden;
				transition: all .2s ease;
				margin-right: 10px;
				&.v-leave-to,
				&.v-enter-from {
					width: 0;
					padding-left: 0;
					padding-right: 0;
					margin-right: 0;
				}
			}
			.activationDate {
				padding: 0 5px;
				& * {
					cursor: pointer;
				}
			}
		}
	}
}
.red {
	color: red !important;
}
.green {
	color: green !important;
}
.fa-icon {
	transition-property: opacity;
	transition-duration: 150ms;
	transition-timing-function: ease;
	&.randomNumber {
		cursor: pointer;
	}
	&.statusIcon {
		&-leave-to,
		&-enter-from {
			opacity: 0;
		}
	}
	&.spinner {
		animation: spinner .2s linear forwards infinite;
	}
}

.cta.mitIDTransition {
	overflow: hidden;
	&-enter-active,
	&-leave-active {
		transition-property: opacity, max-width !important;
		transition-duration: .3s !important;
		transition-timing-function: ease !important;
		* {
			transition-property: opacity !important;
			transition-duration: .3s !important;
			transition-timing-function: ease !important;
		}
	}
	&-leave-to,
	&-enter-from {
		opacity: 0;
		max-width: 120px;
		* {
			opacity: 0;
		}
	}
	&-leave-active {
		position: absolute;
	}
}
</style>
